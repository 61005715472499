/* Estilos exclusivos para a página de mídia */
.midia-page .midia-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  text-align: center;
}

.midia-page .image-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.midia-page .image-item {
  width: 200px; /* Largura fixa para as imagens */
  height: 200px; /* Altura fixa para as imagens */
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.midia-page .image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Garante que a imagem cubra todo o espaço, cortando excessos */
  display: block;
}

.midia-page .image-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.midia-page .expanded-image-container {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.midia-page .expanded-image-container.visible {
  display: flex;
}

.midia-page .expanded-image {
  max-width: 90%;
  max-height: 90%;
  border: 5px solid #fff;
}

@media (max-width: 768px) {
  .midia-page .image-item {
    width: 150px; /* Ajusta o tamanho para telas menores */
    height: 150px;
  }
}

@media (max-width: 480px) {
  .midia-page .image-item {
    width: 100px; /* Ajusta o tamanho para telas ainda menores */
    height: 100px;
  }
}