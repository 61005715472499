/* Estilos exclusivos para a página de Briefings */
.briefings-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    text-align: center;
  }
  
  .briefings-container .image-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  
  .briefings-container .image-item {
    width: 400px; /* Largura fixa para as imagens */
    height: 400px; /* Altura fixa para as imagens */
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .briefings-container .image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Garante que a imagem cubra todo o espaço, cortando excessos */
    display: block;
  }
  
  .briefings-container .image-item:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsividade para telas menores */
  @media (max-width: 768px) {
    .briefings-container .image-item {
      width: 300px; /* Ajusta o tamanho para telas menores */
      height: 300px;
    }
  }
  
  @media (max-width: 480px) {
    .briefings-container .image-item {
      width: 250px; /* Ajusta o tamanho para telas ainda menores */
      height: 250px;
    }
  }
  