body {
  margin: 0;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Faz o corpo ocupar no mínimo a altura da tela */
}

header {
  background-color: #191919;
  color: white;
  padding: 10px 0;
  text-align: center;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  height: 100px;
}

main {
  flex: 1; /* Faz com que o conteúdo principal ocupe o espaço restante */
  padding: 20px;
}

footer {
  background-color: #191919;
  border-top: 2px solid #ccc;
  padding: 10px 0;
  text-align: center;
  margin-top: 20px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  flex-direction: row; /* Mantém o layout horizontal */
  flex-wrap: wrap; /* Permite que os itens se ajustem em telas menores */
}

.contacts, .fields {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contacts h3, .fields h3 {
  margin-bottom: 10px;
  color: #ffffff;
}

.contacts ul, .fields ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  color: #ffffff;
}

.contacts ul {
  display: flex; /* Mantém os itens lado a lado */
  gap: 15px; /* Espaçamento entre os ícones */
}

.contacts ul li, .fields ul li {
  margin: 5px 0;
}

.contacts ul li img, .fields ul li img {
  max-width: 100%;
  height: auto;
  max-height: 2em; /* Ajusta o tamanho da imagem para ser proporcional ao texto */
}

.footer-logo {
  height: 80px; /* Ajuste conforme necessário */
  margin: 10px 0; /* Margem superior e inferior */
  display: block;
  margin-left: auto;
  margin-right: auto; /* Centraliza horizontalmente */
}

@media (max-width: 768px) {
  .container {
      flex-direction: row; /* Mantém os itens em linha no mobile */
      justify-content: space-around; /* Distribui os itens de maneira uniforme */
      flex-wrap: wrap; /* Permite que os itens se ajustem em linhas, se necessário */
  }

  .footer-logo {
      margin: 20px auto; /* Mantém a centralização no mobile */
  }
}
