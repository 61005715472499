/* Estilos para o menu flutuante mobile */
.floating-menu {
  position: fixed; /* Mantém o menu fixo na tela */
  top: 50px; /* Ajuste conforme necessário para ficar abaixo do botão */
  left: 10px;
  background-color: rgba(0, 0, 0, 0.8); /* Preto meio transparente */
  color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: scale(0);
  z-index: 1000;
  /* Garantir que o menu seja exibido corretamente sobre o conteúdo */
  /* Ajuste para garantir que o menu fique fixo no local correto */
}

.floating-menu.show {
  opacity: 1;
  transform: scale(1);
}

.floating-menu.hide {
  opacity: 0;
  transform: scale(0);
}

.floating-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.floating-menu li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #555;
}

.floating-menu li:last-child {
  border-bottom: none;
}

.floating-menu li:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Cor de hover suave */
}

.floating-menu a {
  text-decoration: none;
  color: #ffffff;
}

/* Estilos para o botão toggle */
.toggle-btn {
  position: fixed; /* Mantém o botão fixo na tela */
  top: 10px; /* Ajuste conforme necessário */
  left: 10px;
  background-color: rgba(0, 0, 0, 0.8); /* Preto meio transparente */
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 1.5rem;
  padding: 10px;
  border-radius: 4px; /* Ajuste da hitbox quadrada */
  z-index: 1100;
  transition: background-color 0.3s ease;
  display: inline-block;
}

.toggle-btn:active {
  background-color: rgba(0, 0, 0, 0.6); /* Cor cinza quando o botão é clicado */
}

/* Estilos para o fundo embaçado */
.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 900;
  pointer-events: none; /* Impede interação com o fundo quando o menu está aberto */
  transition: backdrop-filter 0.3s ease;
  display: none; /* Esconde o fundo quando o menu não está aberto */
}

/* Mostrar o fundo embaçado quando o menu está aberto */
.floating-menu.show ~ .blur-background {
  display: block;
}

/* Estilos para o menu horizontal */
.horizontal-menu {
  display: none;
  text-align: center;
  background-color: #787b7b;
  padding: 10px 0;
}

.horizontal-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.horizontal-menu li {
  margin: 0 15px; /* Espaçamento entre os itens do menu */
  position: relative;
}

.horizontal-menu a {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  position: relative;
  padding: 5px 0;
  display: block;
  font-family: 'MedievalSharp', cursive;
}

.horizontal-menu a::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.horizontal-menu a:hover::before {
  transform: scaleX(1);
}

/* Mostrar o menu horizontal em telas maiores */
@media (min-width: 768px) {
  .horizontal-menu {
    display: block;
  }

  .floating-menu {
    display: none;
  }

  .toggle-btn {
    display: none;
  }
}
