.news-card {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Garante que o conteúdo não ultrapasse o card */
  display: flex; /* Usar flexbox para alinhar a imagem e o texto lado a lado */
  align-items: flex-start; /* Alinha o conteúdo ao topo do card */
  flex-direction: row; /* Garante que a direção seja sempre em linha, com a imagem à esquerda */
  position: relative; /* Necessário para posicionar o botão corretamente */
  padding-bottom: 60px; /* Espaço adicional para o botão */
}

.news-card img {
  width: 40%;
  height: auto;
  border-right: 1px solid #ccc; /* Adiciona uma borda à direita da imagem para separação */
  margin-right: 20px; /* Espaçamento entre a imagem e o texto */
}

.news-card-content {
  flex: 1; /* Faz com que o conteúdo ocupe o espaço restante */
  display: flex;
  flex-direction: column;
}

.news-card h3 {
  font-family: 'arial', cursive;
  margin: 0 0 10px; /* Remove a margem superior e adiciona margem inferior */
  color: #000000;
}

.news-card p {
  font-family: 'arial', cursive;
  margin: 0;
  color: #000000;
}

.news-card .field-name {
  font-weight: bold;
  color: #000000; /* Cor do texto em negrito */
}

.news-card .field-content {
  font-weight: normal;
  color: #000000; /* Cor do texto normal */
}

.contact-button {
  position: absolute; /* Posiciona o botão no rodapé do card */
  bottom: 20px; /* Ajusta o posicionamento a partir da parte inferior do card */
  left: 20px; /* Ajusta o posicionamento a partir da borda esquerda do card */
  padding: 10px 15px;
  font-size: 10px;
  color: #fff;
  background-color: #000000; /* Cor do botão, ajuste conforme necessário */
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
}

.contact-button:hover {
  background-color: #646a70; /* Cor do botão ao passar o mouse, ajuste conforme necessário */
}

.expanded-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  cursor: pointer;
}

.expanded-image {
  max-width: 90%;
  max-height: 90%;
}

.field-value {
  white-space: pre-wrap; /* Preserva as quebras de linha */
}
.ribbon {
  position: absolute;
  top: -5px;
  left: -55px;
  background-color: red;
  color: white;
  padding: 30px 60px;
  transform: rotate(-45deg);
  z-index: 1;
}